<template>
  <div class="all-container" v-if="isLoaded">
    <h2>All Companies</h2>
    <h3 class="alphabet">
      <p :class="{'underline':firstLetter==null}" @click="setFirstLetter(null)">All</p>
      <p :class="{'underline':firstLetter==0}" @click="setFirstLetter(0)">A</p>
      <p :class="{'underline':firstLetter==1}" @click="setFirstLetter(1)">B</p>
      <p :class="{'underline':firstLetter==2}" @click="setFirstLetter(2)">C</p>
      <p :class="{'underline':firstLetter==3}" @click="setFirstLetter(3)">D</p>
      <p :class="{'underline':firstLetter==4}" @click="setFirstLetter(4)">E</p>
      <p :class="{'underline':firstLetter==5}" @click="setFirstLetter(5)">F</p>
      <p :class="{'underline':firstLetter==6}" @click="setFirstLetter(6)">G</p>
      <p :class="{'underline':firstLetter==7}" @click="setFirstLetter(7)">H</p>
      <p :class="{'underline':firstLetter==8}" @click="setFirstLetter(8)">I</p>
      <p :class="{'underline':firstLetter==9}" @click="setFirstLetter(9)">J</p>
      <p :class="{'underline':firstLetter==10}" @click="setFirstLetter(10)">K</p>
      <p :class="{'underline':firstLetter==11}" @click="setFirstLetter(11)">L</p>
      <p :class="{'underline':firstLetter==12}" @click="setFirstLetter(12)">M</p>
      <p :class="{'underline':firstLetter==13}" @click="setFirstLetter(13)">N</p>
      <p :class="{'underline':firstLetter==14}" @click="setFirstLetter(14)">O</p>
      <p :class="{'underline':firstLetter==15}" @click="setFirstLetter(15)">P</p>
      <p :class="{'underline':firstLetter==16}" @click="setFirstLetter(16)">Q</p>
      <p :class="{'underline':firstLetter==17}" @click="setFirstLetter(17)">R</p>
      <p :class="{'underline':firstLetter==18}" @click="setFirstLetter(18)">S</p>
      <p :class="{'underline':firstLetter==19}" @click="setFirstLetter(19)">T</p>
      <p :class="{'underline':firstLetter==20}" @click="setFirstLetter(20)">U</p>
      <p :class="{'underline':firstLetter==21}" @click="setFirstLetter(21)">V</p>
      <p :class="{'underline':firstLetter==22}" @click="setFirstLetter(22)">W</p>
      <p :class="{'underline':firstLetter==23}" @click="setFirstLetter(23)">X</p>
      <p :class="{'underline':firstLetter==24}" @click="setFirstLetter(24)">Y</p>
      <p :class="{'underline':firstLetter==25}" @click="setFirstLetter(25)">Z</p>
    </h3>
    <CompanyList
        v-for="(categorizedCompanies, index) in companies"
        v-show="firstLetter==index||firstLetter==null&&categorizedCompanies.length>0"
        :key="index"
        :companies="categorizedCompanies"
    />
  </div>
</template>

<script>
import {ref, onMounted} from "vue";
import getAllCompanies from "@/composables/WishList/getAllCompanies";
import CompanyList from "@/components/WishList/companyList.vue";

export default {
  components: {
    CompanyList,
  },
  async setup() {
    const companies = ref([]);
    const firstLetter = ref(null);
    const isLoaded = ref(false);

    onMounted(async () => {
      await getAllCompanies(companies);
      orderCompanies(companies);
      isLoaded.value = true;
    });
    

    const orderCompanies = (companies) => {
      companies.value.sort((a, b) => a.name.localeCompare(b.name, 'es', {sensitivity: 'base'}))
      let temp = []
      for (let i = 0; i < 26; i++) {
        temp.push([])
      }
      companies.value.forEach((company) => {
        switch (company.name.substring(0, 1).toUpperCase()) {
          case "A":
            temp[0].push(company)
            break;
          case "B":
            temp[1].push(company)
            break;
          case "C":
            temp[2].push(company)
            break;
          case "D":
            temp[3].push(company)
            break;
          case "E":
            temp[4].push(company)
            break;
          case "F":
            temp[5].push(company)
            break;
          case "G":
            temp[6].push(company)
            break;
          case "H":
            temp[7].push(company)
            break;
          case "I":
            temp[8].push(company)
            break;
          case "J":
            temp[9].push(company)
            break;
          case "K":
            temp[10].push(company)
            break;
          case "L":
            temp[11].push(company)
            break;
          case "M":
            temp[12].push(company)
            break;
          case "N":
            temp[13].push(company)
            break;
          case "O":
            temp[14].push(company)
            break;
          case "P":
            temp[15].push(company)
            break;
          case "Q":
            temp[16].push(company)
            break;
          case "R":
            temp[17].push(company)
            break;
          case "S":
            temp[18].push(company)
            break;
          case "T":
            temp[19].push(company)
            break;
          case "U":
            temp[20].push(company)
            break;
          case "V":
            temp[21].push(company)
            break;
          case "W":
            temp[22].push(company)
            break;
          case "X":
            temp[23].push(company)
            break;
          case "Y":
            temp[24].push(company)
            break;
          case "Z":
            temp[25].push(company)
            break;
        }
      })

      companies.value = temp
    }

    const setFirstLetter = (num) => {
      if (firstLetter.value === num) {
        firstLetter.value = null
      } else {
        firstLetter.value = num
      }
    }

    return {
      isLoaded,
      firstLetter,
      companies,
      setFirstLetter,
    }
  }
}
</script>

<style scoped>
.all-container {
  width: 100%;
  margin-top: 5vh;
  animation: fadein 1s;
}

.all-container h2 {
  padding-left: 2vw;
  font-weight: 400;
}

.alphabet {
  padding: 2vh 0 0 1.5vw;
}

.alphabet p {
  font-size: 85%;
  padding: 0.5vh 0.5vw;
  margin-right: 0.4vw;
  display: inline-block;
  font-weight: 400;
}

.alphabet p:hover {
  cursor: pointer;
  background-color: #d1d1d1;
}

.underline {
  border-bottom: 2px black solid;
}

.logo-ads {
  position: relative;
  color: black;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 250px;
}

</style>
